.main-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
}

.sub-layout {
    padding: 0;
    overflow: hidden;
}

.content {
    display: flex;
    height: 100%;

    :global(.ant-tabs) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
    }

    :global(.ant-tabs-content) {
        flex-grow: 1;
        width: 100%;
        height: 100%;
    }

    :global(.ant-tabs-tabpane) {
        height: 100%;
    }
}
