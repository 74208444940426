@import-normalize;

html,
body,
#root {
    height: 100%;
    overflow: hidden;
    background: #333;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// .ant-modal {
//     .ant-modal-close {
//         display: flex !important;
//         top: -10px;
//         right: -10px;
//         align-items: center;
//         justify-content: center;
//         width: 30px;
//         height: 30px;
//         transition: 200ms;

//         &:hover {
//             filter: brightness(1.2);
//             background: transparent;
//         }

//         &::before {
//             content: '';
//             position: absolute;
//             width: 150%;
//             height: 150%;
//             border-radius: 50%;
//         }
//     }
// }

.full-height-table {
    height: 100%;

    .ant-table-wrapper,
    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-table,
    .ant-table-container,
    .ant-table-content,
    table {
        height: 100%;
    }
}

iframe#webpack-dev-server-client-overlay {
    display: none;
}

.ant-modal-wrap:not(.ant-modal-mask + .ant-modal-wrap) {
    pointer-events: none;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #333;
}

::-webkit-scrollbar-thumb {
    background: #555;
}

::-webkit-scrollbar-thumb:hover {
    background: #777;
}

.drag-handle {
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}
